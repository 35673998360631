<script>
	import { terms } from '$stores';
	import { CtaButton } from '$lib/components/ctas';
	import { faCalendarClock } from '@awesome.me/kit-065f90d5b1/icons/classic/regular';

	export let theme = 'blue';
	export let size = 'large';
</script>

<CtaButton {size} {theme} icon={faCalendarClock}>{terms.ctas.form}</CtaButton>
